import { useWeb3React } from '@web3-react/core';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAddPopup, useBlockNumber } from '../application/hooks';
import { checkedTransaction, finalizeTransaction } from './actions';
export function shouldCheck(lastBlockNumber, tx) {
    if (tx.receipt)
        return false;
    if (!tx.lastCheckedBlockNumber)
        return true;
    var blocksSinceCheck = lastBlockNumber - tx.lastCheckedBlockNumber;
    if (blocksSinceCheck < 1)
        return false;
    var minutesPending = (new Date().getTime() - tx.addedTime) / 1000 / 60;
    if (minutesPending > 60) {
        // every 10 blocks if pending for longer than an hour
        return blocksSinceCheck > 9;
    }
    else if (minutesPending > 5) {
        // every 3 blocks if pending more than 5 minutes
        return blocksSinceCheck > 2;
    }
    else {
        // otherwise every block
        return true;
    }
}
export default function Updater() {
    var _a;
    var lastBlockNumber = useBlockNumber();
    var dispatch = useDispatch();
    var state = useSelector(function (state) { return state.transactions; });
    var _b = useWeb3React(), provider = _b.library, chainId = _b.chainId;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    var transactions = chainId ? (_a = state[chainId]) !== null && _a !== void 0 ? _a : {} : {};
    // show popup on confirm
    var addPopup = useAddPopup();
    useEffect(function () {
        if (!chainId || !lastBlockNumber) {
            return;
        }
        Object.keys(transactions)
            .filter(function (hash) { return shouldCheck(lastBlockNumber, transactions[hash]); })
            .forEach(function (hash) {
            provider
                .getTransactionReceipt(hash)
                .then(function (receipt) {
                var _a;
                if (receipt) {
                    dispatch(finalizeTransaction({
                        chainId: chainId,
                        hash: hash,
                        receipt: {
                            blockHash: receipt.blockHash,
                            blockNumber: receipt.blockNumber,
                            contractAddress: receipt.contractAddress,
                            from: receipt.from,
                            status: receipt.status,
                            to: receipt.to,
                            transactionHash: receipt.transactionHash,
                            transactionIndex: receipt.transactionIndex,
                        },
                    }));
                    addPopup({
                        txn: {
                            hash: hash,
                            success: receipt.status == 1,
                            summary: (_a = transactions[hash]) === null || _a === void 0 ? void 0 : _a.summary,
                        },
                    }, hash);
                }
                else {
                    dispatch(checkedTransaction({ chainId: chainId, hash: hash, blockNumber: lastBlockNumber }));
                }
            })
                .catch(function (error) {
                console.error("failed to check transaction hash: " + hash, error);
            });
        });
    }, [chainId, transactions, lastBlockNumber, dispatch, addPopup, provider]);
    return null;
}
