import { useWeb3React } from '@web3-react/core';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useDebounce from '../../hooks/useDebounce';
import { updateBlockNumber } from './actions';
export default function Updater() {
    var _a = useWeb3React(), provider = _a.library, chainId = _a.chainId;
    var dispatch = useDispatch();
    var _b = useState({
        chainId: chainId,
        blockNumber: null,
    }), state = _b[0], setState = _b[1];
    var blockNumberCallback = useCallback(function (blockNumber) {
        setState(function (state) {
            if (typeof state.blockNumber !== 'number')
                return { chainId: chainId, blockNumber: blockNumber };
            return { chainId: chainId, blockNumber: Math.max(blockNumber, state.blockNumber) };
        });
    }, [provider, setState]);
    // attach/detach listeners
    useEffect(function () {
        if (!provider) {
            return undefined;
        }
        provider.on('block', blockNumberCallback);
        return function () {
            provider.removeListener('block', blockNumberCallback);
        };
    }, [blockNumberCallback, provider]);
    var debouncedState = useDebounce(state, 100);
    useEffect(function () {
        if (!debouncedState.chainId || !debouncedState.blockNumber)
            return;
        dispatch(updateBlockNumber({
            chainId: debouncedState.chainId,
            blockNumber: debouncedState.blockNumber,
        }));
    }, [dispatch, debouncedState.blockNumber, debouncedState.chainId]);
    return null;
}
