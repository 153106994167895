import { useCallback, useMemo } from 'react';
import { addPopup, removePopup, toggleWalletModal, toggleSettingsMenu, hideInfoBox, setSlippageTolerance, connectToAccount, disconnectAccount, showInfoBox, acceptTerms, setUseZap, } from './actions';
import { useSelector, useDispatch } from 'react-redux';
import { useWeb3React } from '@web3-react/core';
export function useBlockNumber() {
    var chainId = useWeb3React().chainId;
    return useSelector(function (state) { return state.application.blockNumber[chainId !== null && chainId !== void 0 ? chainId : -1]; });
}
export function useWalletModalOpen() {
    return useSelector(function (state) { return state.application.walletModalOpen; });
}
export function useWalletModalToggle() {
    var dispatch = useDispatch();
    return useCallback(function () { return dispatch(toggleWalletModal()); }, [dispatch]);
}
export function useSettingsMenuOpen() {
    return useSelector(function (state) { return state.application.settingsMenuOpen; });
}
export function useToggleSettingsMenu() {
    var dispatch = useDispatch();
    return useCallback(function () { return dispatch(toggleSettingsMenu()); }, [dispatch]);
}
// returns a function that allows adding a popup
export function useAddPopup() {
    var dispatch = useDispatch();
    return useCallback(function (content, key) {
        dispatch(addPopup({ content: content, key: key }));
    }, [dispatch]);
}
// returns a function that allows removing a popup via its key
export function useRemovePopup() {
    var dispatch = useDispatch();
    return useCallback(function (key) {
        dispatch(removePopup({ key: key }));
    }, [dispatch]);
}
// get the list of active popups
export function useActivePopups() {
    var list = useSelector(function (state) { return state.application.popupList; });
    return useMemo(function () { return list.filter(function (item) { return item.show; }); }, [list]);
}
// check if infobox was previously hidden
export function useIsHiddenInfoBox(key) {
    var list = useSelector(function (state) { return state.application.infoBoxList; });
    return useMemo(function () { var _a; return (_a = list[key]) !== null && _a !== void 0 ? _a : false; }, [key, list]);
}
// hide Infobox for one session
export function useHideInfoBox() {
    var dispatch = useDispatch();
    return useCallback(function (key) {
        dispatch(hideInfoBox({ key: key }));
    }, [dispatch]);
}
// hide Infobox for one session
export function useShowInfoBox() {
    var dispatch = useDispatch();
    return useCallback(function (key) {
        dispatch(showInfoBox({ key: key }));
    }, [dispatch]);
}
export function useSetSlipageTolerance() {
    var dispatch = useDispatch();
    return useCallback(function (slippage) {
        dispatch(setSlippageTolerance({ slippage: slippage }));
    }, [dispatch]);
}
export function useSetZap() {
    var dispatch = useDispatch();
    return useCallback(function (isZap) {
        dispatch(setUseZap({ isZap: isZap }));
    }, [dispatch]);
}
export function useGetSlippageTolerance() {
    var slippage = useSelector(function (state) { return state.application.slippageTolerance; });
    return slippage;
}
export function useGetIsZap() {
    var isZap = useSelector(function (state) { return state.application.isZap; });
    return isZap;
}
export function useGetConnectedAccount() {
    return useSelector(function (state) { return state.application.account; });
}
export function useSetConnectedAccount() {
    var dispatch = useDispatch();
    return useCallback(function (account, connector) {
        dispatch(connectToAccount({ account: account, connector: connector }));
    }, [dispatch]);
}
export function useDisconnectAccount() {
    var dispatch = useDispatch();
    return useCallback(function () {
        dispatch(disconnectAccount());
    }, [dispatch]);
}
export function useAcceptTerms() {
    var dispatch = useDispatch();
    return useCallback(function () {
        dispatch(acceptTerms());
    }, [dispatch]);
}
export function useIsAcceptedTerms() {
    var accepted = useSelector(function (state) { return state.application.acceptedTerms; });
    return accepted;
}
export function useSavedConnector() {
    var connector = useSelector(function (state) { return state.application.connector; });
    return connector;
}
