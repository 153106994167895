var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { save, load } from 'redux-localstorage-simple';
import transactions from './transactions/reducer';
import application, { initialState as appInitialState } from './application/reducer';
var PERSISTED_KEYS = [
    'application.slippageTolerance',
    'application.isZap',
    'application.account',
    'application.infoBoxList',
    'application.acceptedTerms',
    'transactions',
    'timelockTransactions',
    'application.connector',
];
var store = configureStore({
    reducer: {
        application: application,
        transactions: transactions,
    },
    middleware: __spreadArray(__spreadArray([], getDefaultMiddleware({ thunk: false })), [
        save({ states: PERSISTED_KEYS, namespace: 'iron_finance' }),
    ]),
    preloadedState: load({
        states: PERSISTED_KEYS,
        namespace: 'iron_finance',
        preloadedState: {
            application: __assign({}, appInitialState),
        },
    }),
});
export default store;
