import { formatUnits } from '@ethersproject/units';
import React, { useMemo } from 'react';
export var BigNumberValue = function (_a) {
    var value = _a.value, decimals = _a.decimals, fractionDigits = _a.fractionDigits, keepTrailingZeros = _a.keepTrailingZeros, keepCommas = _a.keepCommas;
    var text = useMemo(function () {
        fractionDigits = fractionDigits == null ? 6 : fractionDigits;
        if (!value) {
            return '--';
        }
        var a = formatUnits(value, decimals);
        var _a = a.split('.'), int = _a[0], dec = _a[1];
        dec = (dec === null || dec === void 0 ? void 0 : dec.substr(0, fractionDigits)) || '';
        if (keepTrailingZeros && dec.length < fractionDigits) {
            dec = dec + '0'.repeat(fractionDigits - dec.length);
        }
        int = keepCommas ? int.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : int;
        var delimit = dec ? '.' : '';
        return "" + int + delimit + dec;
    }, [value === null || value === void 0 ? void 0 : value._hex]);
    return React.createElement(React.Fragment, null, text);
};
