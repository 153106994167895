import { useWeb3React } from '@web3-react/core';
import { useEffect, useState } from 'react';
export var useTokensPrice = function (tokens) {
    var _a = useState({}), value = _a[0], setValue = _a[1];
    var chainId = useWeb3React().chainId;
    useEffect(function () {
        if (!(tokens === null || tokens === void 0 ? void 0 : tokens.length) || !chainId) {
            return;
        }
        var controller = new AbortController();
        var signal = controller.signal;
        fetch("https://api-v2.iron.finance/price?chainId=" + chainId, {
            method: 'POST',
            body: JSON.stringify({
                tokens: tokens,
            }),
            headers: {
                'Content-type': 'application/json',
            },
            signal: signal,
        })
            .then(function (res) { return res.json(); })
            .then(function (data) {
            setValue(data);
        });
        return function () {
            controller.abort();
        };
    }, [tokens, chainId]);
    return value;
};
