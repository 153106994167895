import React from 'react';
import { Web3ReactProvider } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { BrowserRouter, Route } from 'react-router-dom';
import { Home } from './views/Home/Home';
import { Header } from './components/Header';
import { Provider } from 'react-redux';
import store from './state';
import Updaters from './state/Updaters';
import Modals from './providers/Modals';
var getLibrary = function (p) {
    return new Web3Provider(p);
};
export var App = function () {
    return (React.createElement(Web3ReactProvider, { getLibrary: getLibrary },
        React.createElement(Provider, { store: store },
            React.createElement(Updaters, null),
            React.createElement(Modals, null,
                React.createElement(Header, null, " "),
                React.createElement(BrowserRouter, null,
                    React.createElement(Route, { path: "/" },
                        React.createElement(Home, null)))))));
};
