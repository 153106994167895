import React, { useRef } from 'react';
import { useCallback, useContext } from 'react';
import { Context } from '../providers/Modals';
var useModalWithFC = function (id) {
    var _a = useContext(Context), onDismiss = _a.onDismiss, onPresent = _a.onPresent;
    var ref = useRef(id || '');
    var handlePresent = useCallback(function (component, props, backdropClick) {
        var el = React.createElement(component, props);
        ref.current = onPresent(el, backdropClick, id);
    }, [id, onPresent]);
    var hideModal = useCallback(function () {
        onDismiss(ref.current);
    }, [onDismiss]);
    return { showModal: handlePresent, hideModal: hideModal };
};
export default useModalWithFC;
