import { useWeb3React } from '@web3-react/core';
import React from 'react';
var url = {
    137: 'https://polygonscan.com',
    56: 'https://bscscan.com',
};
export var ExplorerLink = function (_a) {
    var address = _a.address, children = _a.children, type = _a.type;
    var chainId = useWeb3React().chainId;
    return (React.createElement("a", { target: "_blank", href: url[chainId] + "/" + (type || 'address') + "/" + address }, children));
};
