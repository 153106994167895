var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { useWeb3React } from '@web3-react/core';
import { InjectedConnector } from '@web3-react/injected-connector';
import { NetworkConnector } from '@web3-react/network-connector';
import React, { useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useGetConnectedAccount, useSetConnectedAccount } from '../state/application/hooks';
import { usePendingTransactionCount } from '../state/transactions/hooks';
var connectors = {
    injected: new InjectedConnector({
        supportedChainIds: [137, 56],
    }),
    default: new NetworkConnector({
        urls: {
            137: 'https://rpc-mainnet.maticvigil.com',
            56: 'https://bsc-dataseed.binance.org'
        },
        defaultChainId: 137
    })
};
export var Header = function () {
    var _a = useWeb3React(), account = _a.account, activate = _a.activate, deactivate = _a.deactivate;
    var setAccount = useSetConnectedAccount();
    var isConnecting = useRef();
    var savedAccount = useGetConnectedAccount();
    var saveAccount = useSetConnectedAccount();
    var pending = usePendingTransactionCount();
    var connect = useCallback(function () {
        console.log('connect');
        isConnecting.current = true;
        activate(connectors.injected).catch(function (e) {
            console.log(e);
            isConnecting.current = false;
        });
    }, []);
    var disconnect = useCallback(function () {
        deactivate();
        saveAccount(undefined);
    }, []);
    useEffect(function () {
        if (isConnecting.current && account) {
            setAccount(account);
        }
    }, [account]);
    useEffect(function () {
        if (!savedAccount && !account) {
            activate(connectors.default);
            return;
        }
        if (savedAccount && !account && !isConnecting.current) {
            activate(connectors.injected, function () {
                activate(connectors.default);
            });
        }
    }, [savedAccount, account]);
    return React.createElement(StyledHeader, null,
        React.createElement("div", { className: "logo" }, "my vault"),
        React.createElement("div", { className: "account" }, account ? (React.createElement(React.Fragment, null,
            pending ? React.createElement("strong", null,
                pending,
                "\u00A0") : null,
            React.createElement("strong", { className: "account-number" }, account),
            ' ',
            React.createElement("button", { onClick: disconnect }, "Disconnect"))) : (React.createElement(React.Fragment, null,
            React.createElement("button", { onClick: connect }, "Connect")))));
};
var StyledHeader = styled.header(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  padding: 10px;\n  margin-bottom: 15px;\n\n  .logo {\n    text-transform: uppercase;\n    letter-spacing: 3px;\n    font-weight: bold;\n    margin-right: auto;\n    color: var(--color-magenta);\n  }\n\n  .account {\n\n  }\n\n  .account-number {\n    max-width: 5em;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    display: inline-block;\n    vertical-align: middle;\n  }\n"], ["\n  display: flex;\n  align-items: center;\n  padding: 10px;\n  margin-bottom: 15px;\n\n  .logo {\n    text-transform: uppercase;\n    letter-spacing: 3px;\n    font-weight: bold;\n    margin-right: auto;\n    color: var(--color-magenta);\n  }\n\n  .account {\n\n  }\n\n  .account-number {\n    max-width: 5em;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    display: inline-block;\n    vertical-align: middle;\n  }\n"])));
var templateObject_1;
