import { createReducer, nanoid } from '@reduxjs/toolkit';
import { addPopup, removePopup, toggleWalletModal, toggleSettingsMenu, updateBlockNumber, hideInfoBox, setSlippageTolerance, connectToAccount, disconnectAccount, showInfoBox, acceptTerms, setUseZap, } from './actions';
export var ConnectorNames;
(function (ConnectorNames) {
    ConnectorNames["injected"] = "injected";
    ConnectorNames["walletConnect"] = "walletConnect";
})(ConnectorNames || (ConnectorNames = {}));
export var initialState = {
    blockNumber: {},
    popupList: [],
    infoBoxList: {},
    walletModalOpen: false,
    settingsMenuOpen: false,
    slippageTolerance: 0.001,
    account: undefined,
    acceptedTerms: false,
    isZap: false,
};
export default createReducer(initialState, function (builder) {
    return builder
        .addCase(updateBlockNumber, function (state, action) {
        var _a = action.payload, chainId = _a.chainId, blockNumber = _a.blockNumber;
        if (typeof state.blockNumber[chainId] !== 'number') {
            state.blockNumber[chainId] = blockNumber;
        }
        else {
            state.blockNumber[chainId] = Math.max(blockNumber, state.blockNumber[chainId]);
        }
    })
        .addCase(toggleWalletModal, function (state) {
        state.walletModalOpen = !state.walletModalOpen;
    })
        .addCase(toggleSettingsMenu, function (state) {
        state.settingsMenuOpen = !state.settingsMenuOpen;
    })
        .addCase(addPopup, function (state, _a) {
        var _b = _a.payload, content = _b.content, key = _b.key, _c = _b.removeAfterMs, removeAfterMs = _c === void 0 ? 8000 : _c;
        state.popupList = (key
            ? state.popupList.filter(function (popup) { return popup.key !== key; })
            : state.popupList).concat([
            {
                key: key || nanoid(),
                show: true,
                content: content,
                removeAfterMs: removeAfterMs,
            },
        ]);
    })
        .addCase(removePopup, function (state, _a) {
        var key = _a.payload.key;
        state.popupList.forEach(function (p) {
            if (p.key === key) {
                p.show = false;
            }
        });
    })
        .addCase(hideInfoBox, function (state, _a) {
        var key = _a.payload.key;
        state.infoBoxList[key] = true;
    })
        .addCase(showInfoBox, function (state, _a) {
        var key = _a.payload.key;
        state.infoBoxList[key] = false;
    })
        .addCase(setSlippageTolerance, function (state, _a) {
        var slippage = _a.payload.slippage;
        state.slippageTolerance = slippage;
    })
        .addCase(connectToAccount, function (state, _a) {
        var _b = _a.payload, account = _b.account, connector = _b.connector;
        state.account = account;
        state.connector = connector;
    })
        .addCase(disconnectAccount, function (state) {
        state.account = undefined;
        state.connector = undefined;
    })
        .addCase(acceptTerms, function (state) {
        state.acceptedTerms = true;
    })
        .addCase(setUseZap, function (state, _a) {
        var isZap = _a.payload.isZap;
        state.isZap = isZap;
    });
});
