import { Contract } from '@ethersproject/contracts';
import { useWeb3React } from '@web3-react/core';
import { useMemo } from 'react';
export var useContract = function (address, abi) {
    var _a = useWeb3React(), library = _a.library, account = _a.account;
    return useMemo(function () {
        if (!library || !address) {
            return;
        }
        return new Contract(address, abi, account ? library.getSigner(account) : library);
    }, [library, address, abi, account]);
};
