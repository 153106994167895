import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useContract } from '../hooks/useContract';
import IERC20 from '../abis/IERC20.json';
import { MaxUint256 } from '@ethersproject/constants';
import useHandleTransactionReceipt from '../hooks/useHandleTransactionReceipt';
import { useWeb3React } from '@web3-react/core';
var max = MaxUint256.toHexString();
export var ButtonApprove = function (_a) {
    var tokenAddress = _a.token, spender = _a.spender, tokenTicker = _a.tokenTicker;
    var token = useContract(tokenAddress, IERC20.abi);
    var _b = useState(''), allowance = _b[0], setAllowance = _b[1];
    var handleTransactionReceipt = useHandleTransactionReceipt();
    var account = useWeb3React().account;
    var _c = useState(false), loading = _c[0], setLoading = _c[1];
    useEffect(function () {
        if (token && spender && account) {
            var mounted_1 = true;
            token.allowance(account, spender).then(function (res) {
                if (mounted_1) {
                    setAllowance(res.toHexString());
                }
            });
            return function () {
                mounted_1 = false;
            };
        }
    }, [token, spender, account]);
    var needApprove = useMemo(function () {
        return allowance != max;
    }, [allowance]);
    var approve = useCallback(function () {
        setLoading(true);
        handleTransactionReceipt(token.approve(spender, max), "Approve " + token.address + " to trade on " + spender)
            .then(function (tx) { return tx.wait(); })
            .then(function () {
            setLoading(false);
            setAllowance(max);
        })
            .catch(function () {
            setLoading(false);
        });
    }, [handleTransactionReceipt, token, spender]);
    if (!needApprove || !account) {
        return null;
    }
    return (React.createElement("button", { disabled: loading, onClick: approve },
        "Approve ",
        tokenTicker));
};
