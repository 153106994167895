var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import Modal from './Modal';
var WaitingApproval = function (_a) {
    var message = _a.message, onDismiss = _a.onDismiss;
    return (React.createElement(Modal, { size: "xs" },
        React.createElement(ModalHeader, null,
            React.createElement("button", { onClick: onDismiss }, "X")),
        React.createElement(StyledModalContent, null,
            React.createElement(StyledLoaderContainer, null, "Loading..."),
            React.createElement(StyledTitle, null, "Waiting For Confirmation"),
            React.createElement(StyledMessage, null, message),
            React.createElement(StyledHelper, null, "Confirm this transaction in your wallet"))));
};
var ModalHeader = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: flex-end;\n"])));
var StyledModalContent = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: grid;\n  grid-auto-rows: auto;\n  grid-gap: 5px;\n  grid-template-rows: 1fr;\n  align-items: center;\n"], ["\n  display: grid;\n  grid-auto-rows: auto;\n  grid-gap: 5px;\n  grid-template-rows: 1fr;\n  align-items: center;\n"])));
var StyledLoaderContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  padding: 35px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"], ["\n  padding: 35px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n"])));
var StyledTitle = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  font-size: 20px;\n  text-align: center;\n  font-weight: 500;\n"], ["\n  font-size: 20px;\n  text-align: center;\n  font-weight: 500;\n"])));
var StyledMessage = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  font-size: 16px;\n  text-align: center;\n  font-weight: 400;\n"], ["\n  font-size: 16px;\n  text-align: center;\n  font-weight: 400;\n"])));
var StyledHelper = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  font-size: 13px;\n  text-align: center;\n  font-weight: 400;\n"], ["\n  font-size: 13px;\n  text-align: center;\n  font-weight: 400;\n"])));
export default WaitingApproval;
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
