var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from) {
    for (var i = 0, il = from.length, j = to.length; i < il; i++, j++)
        to[j] = from[i];
    return to;
};
/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useCallback, useState } from 'react';
import styled from 'styled-components';
import { v4 } from 'uuid';
export var Context = createContext({
    onPresent: function () { return ''; },
    onDismiss: function () { },
});
var modalSetter = function (info) { return function (state) {
    var existed = info.id && state.some(function (x) { return x.id === info.id; });
    if (!existed) {
        return __spreadArray(__spreadArray([], state), [info]);
    }
    return state.map(function (item) { return (item.id !== info.id ? item : info); });
}; };
var Modals = function (_a) {
    var children = _a.children;
    var _b = useState([]), modals = _b[0], setModals = _b[1];
    var handlePresent = useCallback(function (modalContent, backdropClick, id) {
        id = id || v4();
        setModals(modalSetter({ id: id, content: modalContent, backdropClick: backdropClick }));
        return id;
    }, []);
    var handleDismiss = useCallback(function (id) {
        setModals(function (data) { return data.filter(function (t) { return t.id !== id; }); });
    }, []);
    var backdropClick = useCallback(function () {
        if (modals.length === 0) {
            return;
        }
        setModals(function (data) { return data.slice(0, data.length - 1); });
    }, [modals]);
    return (React.createElement(Context.Provider, { value: {
            content: modals,
            onPresent: handlePresent,
            onDismiss: handleDismiss,
        } },
        children, modals === null || modals === void 0 ? void 0 :
        modals.map(function (modal) { return (React.createElement(StyledModalWrapper, { key: modal.id },
            React.createElement(StyledBackdrop, { onClick: modal.backdropClick ? backdropClick : undefined }),
            React.createElement(ModalContent, null, React.isValidElement(modal.content) &&
                React.cloneElement(modal.content, {
                    onDismiss: function () { return handleDismiss(modal.id); },
                })))); })));
};
var StyledModalWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  justify-content: center;\n  position: fixed;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  z-index: 10;\n"], ["\n  align-items: center;\n  display: flex;\n  justify-content: center;\n  position: fixed;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  z-index: 10;\n"])));
var ModalContent = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  width: 100%;\n  align-items: center;\n  padding: 20px 0;\n  min-height: 100vh;\n"], ["\n  display: flex;\n  justify-content: center;\n  width: 100%;\n  align-items: center;\n  padding: 20px 0;\n  min-height: 100vh;\n"])));
var StyledBackdrop = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background-color: #222222;\n  opacity: 0.5;\n  position: absolute;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n"], ["\n  background-color: #222222;\n  opacity: 0.5;\n  position: absolute;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n"])));
export default Modals;
var templateObject_1, templateObject_2, templateObject_3;
