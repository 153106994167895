var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
import { Container } from '../../components/Container';
import { useTokensPrice } from '../../hooks/usePrice';
import { useVaults } from '../../hooks/useVaults';
import { Vault } from './components/Vault';
var tokens = ['ICE', 'ICE_USDC_DLP'];
export var Home = function () {
    var vaults = useVaults();
    var prices = useTokensPrice(tokens);
    return (React.createElement(Container, null,
        React.createElement(VaultList, null, vaults.map(function (t) { return (React.createElement("li", { key: t.address },
            React.createElement(Vault, { address: t.address, wantDecimals: t.wantDecimals, wantToken: t.wantToken, wantAddress: t.wantAddress, masterChef: t.masterChef, poolId: t.poolId, reward: t.reward, prices: prices }))); }))));
};
var VaultList = styled.ul(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  list-style: none;\n  padding: 0;\n"], ["\n  list-style: none;\n  padding: 0;\n"])));
var templateObject_1;
