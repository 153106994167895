import { createAction } from '@reduxjs/toolkit';
export var updateBlockNumber = createAction('app/updateBlockNumber');
export var toggleWalletModal = createAction('app/toggleWalletModal');
export var toggleSettingsMenu = createAction('app/toggleSettingsMenu');
export var addPopup = createAction('app/addPopup');
export var removePopup = createAction('app/removePopup');
export var hideInfoBox = createAction('app/hideInfoBox');
export var showInfoBox = createAction('app/showInfoBox');
export var setSlippageTolerance = createAction('app/setSlippageTolerance');
export var setUseZap = createAction('app/setUseZap');
export var refreshGlobalInfo = createAction('app/refreshGlobalInfo');
export var connectToAccount = createAction('app/connectToAccount');
export var disconnectAccount = createAction('app/disconnectAccount');
export var acceptTerms = createAction('app/acceptTerms');
