import { useWeb3React } from '@web3-react/core';
import { useMemo } from 'react';
var vaultPolygon = [
    {
        address: '0xb7D39c4762dd40AA49c8407123e5bE65846E87DF',
        wantToken: 'ICE_USDC_DLP',
        wantAddress: '0x34832d9ac4127a232c1919d840f7aae0fcb7315b',
        wantDecimals: 18,
        masterChef: '0x1fD1259Fa8CdC60c6E8C86cfA592CA1b8403DFaD',
        poolId: 2,
        reward: 'ICE',
    },
];
var vaultBsc = [
    {
        address: '0xFB82d18423f661d3a3652e1C471689dA3cAEC187',
        // address: '0xFCe823573D7253DAB4413F524b3a690CEF30eC6B',
        wantToken: 'IRON/STEEL LP',
        wantAddress: '0x453a688c33696c42a73230B0b3AC1e4676D32aA6',
        wantDecimals: 18,
        masterChef: '0xC5a992dD7ba108e3349D2Fd8e8E126753Ca8Ce34',
        poolId: 2,
        reward: 'STEEL',
    },
];
var vaults = {
    137: vaultPolygon,
    56: vaultBsc,
};
export var useVaults = function () {
    var chainId = useWeb3React().chainId;
    return useMemo(function () {
        return chainId ? vaults[chainId] || [] : [];
    }, [chainId]);
};
