var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import styled from 'styled-components';
var Modal = function (_a) {
    var children = _a.children, size = _a.size, padding = _a.padding, background = _a.background;
    return (React.createElement(Container, { size: size || 'sm' },
        React.createElement(StyledModal, null,
            React.createElement(Card, { width: "auto", padding: padding, background: background },
                React.createElement(CardContent, null, children)))));
};
var StyledModal = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  box-shadow: 10px 10px 0px 0px var(--color-black-light);\n  position: relative;\n  border: 3px solid var(--color-blue-light);\n  color: var(--color-black);\n  background-color: var(--color-black-ligth);\n"], ["\n  box-shadow: 10px 10px 0px 0px var(--color-black-light);\n  position: relative;\n  border: 3px solid var(--color-blue-light);\n  color: var(--color-black);\n  background-color: var(--color-black-ligth);\n"])));
var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject([""], [""])));
var Card = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background-color: var(--color-blue-light);\n  padding: 3px;\n  border: 3px solid var(--color-black-light);\n"], ["\n  background-color: var(--color-blue-light);\n  padding: 3px;\n  border: 3px solid var(--color-black-light);\n"])));
var CardContent = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  padding: 14px;\n  border: 3px solid var(--color-black-light);\n"], ["\n  padding: 14px;\n  border: 3px solid var(--color-black-light);\n"])));
export default Modal;
export var ModalUpper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  /* background-color: ", "; */\n  padding: 20px 25px;\n  display: grid;\n  grid-auto-rows: auto;\n  grid-template-rows: 1fr;\n  grid-gap: 15px;\n"], ["\n  /* background-color: ", "; */\n  padding: 20px 25px;\n  display: grid;\n  grid-auto-rows: auto;\n  grid-template-rows: 1fr;\n  grid-gap: 15px;\n"])), function (props) { return props.theme.color.blue[600]; });
export var ModalCenter = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject([""], [""])));
export var ModalContent = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  padding: 20px 25px;\n"], ["\n  padding: 20px 25px;\n"])));
export var ModalLower = styled.div(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  padding: 25px 25px;\n  font-size: 15px;\n"], ["\n  padding: 25px 25px;\n  font-size: 15px;\n"])));
export var ModalHeader = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: ", ";\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: ", ";\n"])), function (_a) {
    var center = _a.center;
    return (center ? 'center' : 'flex-start');
});
export var ModalTitle = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  font-size: 20px;\n  color: var(--color-cyan);\n  font-weight: 500;\n"], ["\n  font-size: 20px;\n  color: var(--color-cyan);\n  font-weight: 500;\n"])));
export var ModalTitleCentered = styled(ModalTitle)(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  text-align: center;\n"], ["\n  text-align: center;\n"])));
export var ModalCloseButton = styled.div(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  margin-left: auto;\n  display: flex;\n  align-items: center;\n"], ["\n  margin-left: auto;\n  display: flex;\n  align-items: center;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12;
