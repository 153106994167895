import { useWeb3React } from '@web3-react/core';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addTransaction, clearAllTransactions } from './actions';
// helper that can take a ethers library transaction response and add it to the list of transactions
export function useTransactionAdder() {
    var _a = useWeb3React(), chainId = _a.chainId, account = _a.account;
    var dispatch = useDispatch();
    return useCallback(function (response, _a) {
        var _b = _a === void 0 ? {} : _a, summary = _b.summary, approval = _b.approval, redemption = _b.redemption;
        // if (!account) return;
        if (!chainId)
            return;
        var hash = response.hash;
        if (!hash) {
            throw Error('No transaction hash found.');
        }
        dispatch(addTransaction({ hash: hash, from: account, chainId: chainId, approval: approval, summary: summary, redemption: redemption }));
    }, [dispatch, chainId, account]);
}
// returns all the transactions for the current chain
export function useAllTransactions() {
    var _a;
    var chainId = useWeb3React().chainId;
    var state = useSelector(function (state) { return state.transactions; });
    return chainId ? (_a = state[chainId]) !== null && _a !== void 0 ? _a : {} : {};
}
export function useIsTransactionPending(transactionHash) {
    var transactions = useAllTransactions();
    if (!transactionHash || !transactions[transactionHash]) {
        return false;
    }
    return !transactions[transactionHash].receipt;
}
/**
 * Returns whether a transaction happened in the last day (86400 seconds * 1000 milliseconds / second)
 * @param tx to check for recency
 */
export function isTransactionRecent(tx) {
    return new Date().getTime() - tx.addedTime < 86400000;
}
// returns whether a token has a pending approval transaction
export function useHasPendingApproval(tokenAddress, spender) {
    var allTransactions = useAllTransactions();
    return useMemo(function () {
        return typeof tokenAddress === 'string' &&
            typeof spender === 'string' &&
            Object.keys(allTransactions).some(function (hash) {
                var tx = allTransactions[hash];
                if (!tx)
                    return false;
                if (tx.receipt) {
                    return false;
                }
                else {
                    var approval = tx.approval;
                    if (!approval)
                        return false;
                    return (approval.spender === spender &&
                        approval.tokenAddress === tokenAddress &&
                        isTransactionRecent(tx));
                }
            });
    }, [allTransactions, spender, tokenAddress]);
}
export function useHasPendingRedemption(poolAddress) {
    var allTransactions = useAllTransactions();
    return useMemo(function () {
        return typeof poolAddress === 'string' &&
            Object.keys(allTransactions).some(function (hash) {
                var tx = allTransactions[hash];
                if (!tx || tx.receipt || !isTransactionRecent(tx)) {
                    return false;
                }
                var redemption = tx.redemption;
                if (!redemption) {
                    return false;
                }
                return redemption.poolAddress === poolAddress;
            });
    }, [allTransactions, poolAddress]);
}
export function useClearAllTransactions() {
    var chainId = useWeb3React().chainId;
    var dispatch = useDispatch();
    return {
        clearAllTransactions: useCallback(function () { return dispatch(clearAllTransactions({ chainId: chainId })); }, [
            chainId,
            dispatch,
        ]),
    };
}
export var usePendingTransactionCount = function () {
    var allTransactions = useAllTransactions();
    return Object.entries(allTransactions).filter(function (_a) {
        var _ = _a[0], t = _a[1];
        return !t.receipt;
    }).length;
};
